import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GridModule } from '@ui/grid/grid.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationPanelModule } from '@ui/navigation-panel/navigation-panel.module';
import { initTheme } from './initializers/theme.initialiser';
import { ThemeState } from '@shared/states/theme.state';
import { AppInjector } from '@shared/config/app-injector';
import { CommonModule } from '@angular/common';
import { ToastModule } from '@ui/toast/toast.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalInterceptor } from './interceptors/global.interceptor';

@NgModule({
  declarations: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initTheme,
      multi: true,
      deps: [ThemeState],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    GridModule,
    BrowserAnimationsModule,
    NavigationPanelModule,
    ToastModule,
  ],
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector.Injector = injector;
  }
}
