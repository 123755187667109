<div class="app-message-skeleton__body">
  <ng-container *ngIf="isErrorState">
    <app-svg subType="danger">
      <svg width="46" height="42" viewBox="0 0 46 42" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25 2.94047e-06C24.4477 2.96461e-06 24 0.447715 24 1C24 1.55229 24.4477 2 25 2L25 2.94047e-06ZM11.8579 6.85788C11.8579 6.30559 11.4101 5.85788 10.8579 5.85788L1.85785 5.85788C1.30557 5.85788 0.857851 6.3056 0.857851 6.85789C0.857852 7.41017 1.30557 7.85788 1.85785 7.85788L9.85785 7.85788L9.85786 15.8579C9.85786 16.4102 10.3056 16.8579 10.8579 16.8579C11.4101 16.8579 11.8579 16.4102 11.8579 15.8579L11.8579 6.85788ZM25 2C35.4934 2 44 10.5066 44 21L46 21C46 9.40202 36.598 2.43351e-06 25 2.94047e-06L25 2ZM44 21C44 31.4934 35.4934 40 25 40L25 42C36.598 42 46 32.598 46 21L44 21ZM25 40C14.5066 40 6 31.4934 6 21L4 21C4 32.598 13.402 42 25 42L25 40ZM6 21C6 15.753 8.12555 11.0044 11.565 7.56498L10.1507 6.15077C6.35159 9.94993 4 15.2013 4 21L6 21Z"
        />
        <rect x="23" y="9.66602" width="4" height="16" rx="2" />
        <rect x="23" y="28.332" width="4" height="4" rx="2" />
      </svg>
    </app-svg>

    <span class="heading-3"> Что-то пошло не так </span>
    <span class="control-heading"> Ошибка загрузки данных </span>

    <app-button *ngIf="showReloadButtonOnError" style="margin-top: 20px" (click)="onReload()">
      Загрузить заново
    </app-button>
  </ng-container>

  <ng-container *ngIf="!isErrorState && isEmptyState">
    <app-svg type="skeleton" style="margin-bottom: 10px; width: 48px; height: 48px">
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3ZM5 24C5 13.5066 13.5066 5 24 5C34.4934 5 43 13.5066 43 24C43 34.4934 34.4934 43 24 43C13.5066 43 5 34.4934 5 24ZM23.9731 10.0774C22.5515 10.0774 21.424 11.2049 21.424 12.6266V25.4213C21.424 26.843 22.5515 27.9705 23.9731 27.9705C25.3948 27.9705 26.5223 26.843 26.5223 25.4213V12.6266C26.5223 11.2049 25.3948 10.0774 23.9731 10.0774ZM20.6396 34.5884C20.6396 36.4022 22.1103 37.9219 23.9731 37.9219C25.836 37.9219 27.3066 36.4513 27.3066 34.5884C27.3066 32.7746 25.836 31.2549 23.9731 31.2549C22.1593 31.2549 20.6396 32.7256 20.6396 34.5884Z"
          fill="#E6EAF6"
          fill-opacity="0.5"
        />
      </svg>
    </app-svg>

    <span class="heading-3">{{ title || 'Нет данных' }}</span>

    <span class="heading-4">{{ message || 'Данные отсутствуют' }}</span>
  </ng-container>
</div>
