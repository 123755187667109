import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { contentAnimation } from '@shared/animations/animations';

@Component({
  selector: 'app-message-skeleton',
  templateUrl: './message-skeleton.component.html',
  styleUrls: ['./message-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [contentAnimation],
  host: {
    '[@contentAnimation]': 'true',
    '[class.app-text-danger]': 'isErrorState',
    '[attr.is-shadow]': 'shadow',
  },
})
export class MessageSkeletonComponent {
  @Input() public title: string;
  @Input() public message: string;
  @Input() public isErrorState: boolean;
  @Input() public isEmptyState: boolean;
  @Input() public shadow: boolean = true;
  @Input() public showReloadButtonOnError: boolean = true;

  @Output() public readonly reload = new EventEmitter<void>();

  constructor() {}

  public onReload(): void {
    this.reload.emit();
  }
}
