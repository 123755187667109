<ng-container
  *ngLet="{
    _user: userState.user$ | async,
    _order: orderState.order$ | async,
    _mobile: (settingsService.screenBreakpoint$ | async) === 'XXSmall'
  } as obj"
  [ngVar]="false"
  #confirmationDialog="ngVar"
>
  <div class="app-bg-main app-border-radius-8 pdng-18" (click)="$event.stopPropagation()">
    <div
      class="--flex --gap-10 --align-center --crsr-pntr"
      [routerLink]="'/login'"
      (click)="toLogin()"
    >
      <app-svg src="assets/img/avatar_24X24.svg"></app-svg>

      <span class="subtitle-2">Авторизоваться</span>
    </div>

    <div
      class="--flex --gap-10 --align-center --crsr-pntr"
      (click)="onClickResetOrder(confirmationDialog)"
    >
      <app-svg src="/assets/img/reset.svg"> </app-svg>

      <span class="subtitle-2">Сбросить все параметры</span>
    </div>

    <ng-container *ngIf="obj._user && !obj._user?.anonymous && !isLoginPage">
      <div class="--flex --col --gap-12">
        <div
          class="--flex --gap-10 --align-center pdng-btm-14 app-border-bottom"
          *ngIf="obj._mobile"
        >
          <span>г. {{ obj._order?.city.title }}</span>
        </div>

        <div
          class="--flex app-profile-menu-pointer --gap-10 --align-center pdng-btm-16 app-border-bottom"
          (click)="toProfile()"
        >
          <span>Перейти в профиль</span>
        </div>

        <div class="--flex mrgn-btm-14 app-profile-menu-pointer --gap-10 --align-center">
          <span (click)="logout()">Выход</span>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="confirmationDialog.value">
    <app-dialog-notification
      [calculatePosition]="!obj._mobile"
      (closeEvent)="confirmationDialog.value = false"
    >
      <app-dialog-notification-title>
        <span class="heading-secondary-bold-24">Сброс параметров</span>
      </app-dialog-notification-title>

      <app-dialog-notification-description>
        Вы хотите сбросить все параметры и опции?
      </app-dialog-notification-description>

      <app-dialog-notification-footer>
        <div class="--flex --gap-10 w-100 pdng-y-20 pdng-x-0">
          <app-button class="app-border w-50" (click)="confirmationDialog.value = false">
            Нет
          </app-button>

          <app-button
            type="inverse"
            class="w-50"
            (click)="confirmationDialog.value = false; resetOrder()"
          >
            Да
          </app-button>
        </div>
      </app-dialog-notification-footer>
    </app-dialog-notification>
  </ng-container>
</ng-container>
