import { Env } from '@environments/environment.types';

export const environment: Env = {
  production: true,
  apiMarketPath: '/market-api',
  apiLivingPath: '/living-api',
  apiVersion: {
    v0: '/v0',
    v1: '/v1',
    v2: '/v2',
  },
};
