<ng-container
  *ngLet="{
    show: toastService.showToast$ | async,
    style: toastService.toastStyle$ | async,
    text: toastService.toastText$ | async,
    _screen: settingsService.screenBreakpoint$ | async
  } as obj"
>
  <div class="app-toast__wrapper" [class.mobile]="obj._screen === 'XXSmall'">
    <div *ngIf="obj.show" [class]="obj.style">
      <div class="--flex --jc-space --align-center">
        <span class="--flex --align-center gap-16">
          <app-svg [src]="toastIcon(obj.style)" type="none"></app-svg>
          <h3 class="heading-3">{{ obj.text?.heading }}</h3>
        </span>
        <app-svg src="/assets/img/close.svg" (click)="close()"></app-svg>
      </div>
      <p class="subtitle-2-special-2 pdng-lft-40">{{ obj.text?.message }}</p>
    </div>
  </div>
</ng-container>
