import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SettingsService } from '@api/settings.service';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@shared/authentication/authentication.service';
import { BaseObject } from '@shared/base/base-object';
import { AppState } from '@shared/states/app-state.service';
import { ThemeState } from '@shared/states/theme.state';
import { UserState } from '@shared/states/user.state';
import { first, takeUntil } from 'rxjs';

@Component({
  selector: 'app-navigation-panel',
  templateUrl: './navigation-panel.component.html',
  styleUrls: ['./navigation-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.mobile]': 'settingsService.screenBreakpoint$.value === "XXSmall"',
    '[class.hidden]': '!isAvailablePage',
  },
})
export class NavigationPanelComponent extends BaseObject {
  public get isAvailablePage(): boolean {
    return location.pathname !== '/login';
  }

  public environment = environment;
  public location = location;

  constructor(
    public themeState: ThemeState,
    private authService: AuthenticationService,
    public settingsService: SettingsService,
    private appStateService: AppState,
    public userState: UserState,
  ) {
    super();
  }

  public logout(): void {
    this.authService
      .logout()
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe(() => location.replace('login'));
  }

  public changeLeftMenuStateHandler(): void {
    let leftMenuState = this.appStateService.leftMenuState$.value;

    if (leftMenuState === 'open') {
      leftMenuState = 'close';
    } else {
      leftMenuState = 'open';
    }

    this.appStateService.leftMenuState$.next(leftMenuState);
  }
}
