import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StaticContentState {
  private staticContentValue$: BehaviorSubject<any> = new BehaviorSubject(null);
  public staticContent$: Observable<any> = this.staticContentValue$.asObservable();

  public loadContentState(value: any) {
    this.staticContentValue$.next(value);
  }
}
