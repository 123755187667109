import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppState {
  public leftMenuState$ = new BehaviorSubject<'open' | 'close'>('close');

  constructor() {}
}
