import { Injectable } from '@angular/core';
import { StaticContentApiService } from '@api/static-content-api.service';
import { StaticContentState } from '@shared/states/static-content.state';

@Injectable({ providedIn: 'root' })
export class StaticContentHandlerService {
  constructor(
    private staticContentApiService: StaticContentApiService,
    private staticContentState: StaticContentState,
  ) {}

  public getContent(): void {
    this.staticContentApiService
      .getStaticContent()
      .subscribe((content) => this.staticContentState.loadContentState(content));
  }
}
