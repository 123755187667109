import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-dialog-notification-description',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-dialog-notification__body subtitle-2',
  },
})
export class DialogNotificationDescriptionComponent {
  constructor() {}
}
