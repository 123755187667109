import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from '@ui/toast/toast.component';
import { ToastService } from '@ui/toast/toast.service';
import { DirectivesModule } from '@shared/directives/directives.module';
import { SvgModule } from '@ui/svg/svg.module';

@NgModule({
  imports: [CommonModule, DirectivesModule, SvgModule],
  declarations: [ToastComponent],
  providers: [ToastService],
  exports: [ToastComponent],
})
export class ToastModule {}
