import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@api/settings.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StaticContentApiService {
  constructor(
    private http: HttpClient,
    private settings: SettingsService,
  ) {}

  public getStaticContent(): Observable<any> {
    return this.http.get<any>(`${this.settings.v2}client/static-content/get`);
  }
}
