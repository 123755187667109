import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SettingsService } from '@api/settings.service';

@Component({
  selector: 'app-dialog-notification',
  templateUrl: './dialog-notification.component.html',
  styleUrls: ['./dialog-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogNotificationComponent {
  @Input() public calculatePosition = true;

  @Output() public readonly closeEvent = new EventEmitter<void>();

  public Math = Math;

  constructor(
    public settingsService: SettingsService,
    public cd: ChangeDetectorRef,
  ) {}

  public _onCloseClick(): void {
    this.closeEvent.next();
  }
}
