import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-dialog-notification-title',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-dialog-notification__title heading-4',
  },
})
export class DialogNotificationTitleComponent {
  constructor() {}
}
