import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TOAST_STYLE } from '@ui/toast/toast.constant';

interface ToastText {
  message: string;
  heading: string;
}

type TOAST_STYLE_TYPE = 'success' | 'info' | 'danger';

@Injectable({ providedIn: 'root' })
export class ToastService {
  public showToast$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public toastText$: BehaviorSubject<ToastText> = new BehaviorSubject<ToastText>(null);
  public toastStyle$: BehaviorSubject<string> = new BehaviorSubject<string>(TOAST_STYLE.success);

  public showToast(toastStyle: TOAST_STYLE_TYPE, toastText: ToastText) {
    this.toastStyle$.next(TOAST_STYLE[toastStyle]);
    this.toastText$.next(toastText);
    this.showToast$.next(true);
  }

  public delay(timeout = 2000) {
    setTimeout(() => {
      this.closeToast();
    }, timeout);
  }

  public closeToast(): void {
    this.showToast$.next(false);
  }
}
