import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
  UrlSegment,
  Route,
} from '@angular/router';
import { first, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '@shared/authentication/authentication.service';
import { UserState } from '@shared/states/user.state';
import { UserModel } from '@shared/authentication/interfaces';
import { SettingsService } from '@api/settings.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private userState: UserState,
    private settingsService: SettingsService,
  ) {}

  private authSubscribeLogic(result: UserModel): void {
    if (result) {
      this.userState.user$.next(result);
      this.userState.userIsLogin$.next(true);
      this.router.navigate(['configurator']);
    }
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    if (route.queryParams['isStaff']) {
      this.settingsService.employeeEntryMethod().subscribe(() => {
        location.replace('/');
      });
    }

    if (route.queryParams['source']) {
      this.settingsService.savedParentUrl.next(route.queryParams['source']);
    }

    return this.auth.authCheck().pipe(
      switchMap((valid) => {
        if (valid) {
          this.userState.userIsLogin$.next(true);
          return of(true);
        }

        return this.auth.anonymousLogin().pipe(
          first(),
          map((result) => {
            this.authSubscribeLogic(result);

            return !!result;
          }),
        );
      }),
    );
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return of(false);
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return of(false);
  }
}
