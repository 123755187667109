import { Component, OnInit } from '@angular/core';
import { ToastService } from '@ui/toast/toast.service';
import { SettingsService } from '@api/settings.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  constructor(
    public toastService: ToastService,
    public settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this.toastService.delay();
  }

  public toastIcon(style: string): string {
    switch (style) {
      case 'success-toast':
        return '/assets/img/toast/toast-success.svg';
      case 'info-toast':
        return '/assets/img/toast/toast-info.svg';
      case 'danger-toast':
        return '/assets/img/toast/toast-danger.svg';
      default:
        return '';
    }
  }

  public close(): void {
    this.toastService.closeToast();
  }
}
