<ng-container *ngIf="isAvailablePage">
  <ng-container *ngLet="settingsService.screenBreakpoint$ | async as screen">
    <ng-container *ngIf="screen !== 'XXSmall'; else mobileTemplate">
      <div class="panel_logo mrgn-rgt-60 mrgn-top-16">
        <img [src]="themeState.logo$ | async" />
      </div>

      <app-account-nav class="ml-auto"></app-account-nav>
    </ng-container>
  </ng-container>

  <ng-template #mobileTemplate>
    <div class="--flex --jc-space --align-center w-100">
      <img [src]="themeState.logo$ | async" (click)="logout()" />

      <app-account-nav class="ml-auto"></app-account-nav>
    </div>
  </ng-template>
</ng-container>
