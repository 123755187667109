import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ButtonModule } from '@ui/button/button.module';
import { DialogModule } from '@ui/dialog/dialog.module';
import { SvgModule } from '../svg/svg.module';
import { AccountNavComponent } from './account-nav/account-nav.component';
import { ProfileMenuComponent } from './dialogs/profile-menu/profile-menu.component';
import { NavigationPanelComponent } from './navigation-panel.component';

@NgModule({
  declarations: [NavigationPanelComponent, AccountNavComponent, ProfileMenuComponent],
  exports: [NavigationPanelComponent],
  imports: [
    CommonModule,
    RouterLink,
    NgOptimizedImage,
    MatMenuModule,
    RouterLinkActive,
    SvgModule,
    DirectivesModule,
    ButtonModule,
    DialogModule,
  ],
})
export class NavigationPanelModule {}
