import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageSkeletonComponent } from './message-skeleton/message-skeleton.component';
import { ButtonModule } from '@ui/button/button.module';
import { SpinnerModule } from '@ui/spinner/spinner.module';
import { SvgModule } from '@ui/svg/svg.module';

@NgModule({
  imports: [CommonModule, SpinnerModule, SvgModule, ButtonModule],
  declarations: [MessageSkeletonComponent],
  exports: [MessageSkeletonComponent],
})
export class SkeletonModule {}
