import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@ui/button/button.module';
import { SvgModule } from '@ui/svg/svg.module';
import { DialogComponent } from '@ui/dialog/dialog/dialog.component';
import { DialogHeaderComponent } from '@ui/dialog/dialog-header/dialog-header.component';
import { DialogSubHeaderComponent } from '@ui/dialog/dialog-subheader/dialog-subheader.component';
import { DialogFooterComponent } from '@ui/dialog/dialog-footer/dialog-footer.component';
import { DialogOutletComponent } from '@ui/dialog/dialog-outlet/dialog-outlet.component';
import { LazyDialogOutletComponent } from '@ui/dialog/lazy-dialog-outlet/lazy-dialog-outlet.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { CoreModule } from '@core/core.module';
import { SpinnerModule } from '@ui/spinner/spinner.module';
import { SkeletonModule } from '@ui/skeleton/skeleton.module';
import { DialogService } from './dialog.service';
import { DialogNotificationComponent } from './dialog-notification/dialog-notification.component';
import { DialogNotificationTitleComponent } from './dialog-notification/dialog-notification-title.component';
import { DialogNotificationDescriptionComponent } from './dialog-notification/dialog-notification-description.component';
import { DialogNotificationFooterComponent } from './dialog-notification/dialog-notification-footer.component';

@NgModule({
  imports: [
    CommonModule,
    SvgModule,
    MatDialogModule,
    ButtonModule,
    DirectivesModule,
    CoreModule,
    SpinnerModule,
    SkeletonModule,
  ],
  declarations: [
    DialogComponent,
    DialogHeaderComponent,
    DialogSubHeaderComponent,
    DialogFooterComponent,
    DialogOutletComponent,
    LazyDialogOutletComponent,
    DialogNotificationComponent,
    DialogNotificationTitleComponent,
    DialogNotificationDescriptionComponent,
    DialogNotificationFooterComponent,
  ],
  exports: [
    DialogComponent,
    DialogHeaderComponent,
    DialogSubHeaderComponent,
    DialogFooterComponent,
    DialogNotificationComponent,
    DialogNotificationTitleComponent,
    DialogNotificationDescriptionComponent,
    DialogNotificationFooterComponent,
  ],
  providers: [DialogService],
})
export class DialogModule {}
