import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LetDirective } from './let.directive';
import { VarDirective } from './var.directive';
import { ResizeObserverDirective } from './resize-observer.directive';
import { TemplateContextTypeDirective } from './template-context-type.directive';
import { InputAutofocusDirective } from './input-autofocus.directive';
import { PriceCategoryDirective } from './price-category.directive';

@NgModule({
  declarations: [
    LetDirective,
    VarDirective,
    ResizeObserverDirective,
    TemplateContextTypeDirective,
    InputAutofocusDirective,
    PriceCategoryDirective,
  ],
  exports: [
    LetDirective,
    VarDirective,
    ResizeObserverDirective,
    TemplateContextTypeDirective,
    InputAutofocusDirective,
    PriceCategoryDirective,
  ],
  imports: [CommonModule],
})
export class DirectivesModule {}
