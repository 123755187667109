/**
 *  This file was auto generated by angular schematics.
 *  For update use script from package.json
 */

import { ColorsMap } from './generated-types';

export const $darkPalette: ColorsMap = {
  /** as theme map variable danger-color */
  dangerColor: {
    type: 'color',

    rgba: 'rgba(255, 64, 88, 1)',
    hex: '#FF4058',
    r: 255,
    g: 64,
    b: 88,
    a: 1,
  },

  /** as theme map variable success-color */
  successColor: {
    type: 'color',

    rgba: 'rgba(16, 164, 38, 1)',
    hex: '#10A426',
    r: 16,
    g: 164,
    b: 38,
    a: 1,
  },

  /** as theme map variable success-light-color */
  successLightColor: {
    type: 'color',

    rgba: 'rgba(15, 172, 39, 1)',
    hex: '#0FAC27',
    r: 15,
    g: 172,
    b: 39,
    a: 1,
  },

  /** as theme map variable success-blue-color */
  successBlueColor: {
    type: 'color',

    rgba: 'rgba(80, 146, 225, 1)',
    hex: '#5092E1',
    r: 80,
    g: 146,
    b: 225,
    a: 1,
  },

  /** as theme map variable warning-color */
  warningColor: {
    type: 'color',

    rgba: 'rgba(255, 176, 57, 1)',
    hex: '#FFB039',
    r: 255,
    g: 176,
    b: 57,
    a: 1,
  },

  /** as theme map variable bg-main-color */
  bgMainColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 1)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  },

  /** as theme map variable bg-additional-color */
  bgAdditionalColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 1)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  },

  /** as theme map variable bg-block-main-color */
  bgBlockMainColor: {
    type: 'color',

    rgba: 'rgba(242, 242, 242, 1)',
    hex: '#F2F2F2',
    r: 242,
    g: 242,
    b: 242,
    a: 1,
  },

  /** as theme map variable bg-extra-color */
  bgExtraColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 1)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  },

  /** as theme map variable text-color */
  textColor: {
    type: 'color',

    rgba: 'rgba(255, 255, 255, 1)',
    hex: '#FFFFFF',
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },

  /** as theme map variable text-additional-color */
  textAdditionalColor: {
    type: 'color',

    rgba: 'rgba(115, 115, 115, 1)',
    hex: '#737373',
    r: 115,
    g: 115,
    b: 115,
    a: 1,
  },

  /** as theme map variable text-additional-2-color */
  textAdditional2Color: {
    type: 'color',

    rgba: 'rgba(158, 158, 158, 1)',
    hex: '#9E9E9E',
    r: 158,
    g: 158,
    b: 158,
    a: 1,
  },

  /** as theme map variable text-extra-color */
  textExtraColor: {
    type: 'color',

    rgba: 'rgba(175, 175, 175, 1)',
    hex: '#AFAFAF',
    r: 175,
    g: 175,
    b: 175,
    a: 1,
  },

  /** as theme map variable text-inverse-color */
  textInverseColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 1)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  },

  /** as theme map variable text-static-color */
  textStaticColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 1)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  },

  /** as theme map variable text-link-color */
  textLinkColor: {
    type: 'color',

    rgba: 'rgba(176, 197, 245, 1)',
    hex: '#B0C5F5',
    r: 176,
    g: 197,
    b: 245,
    a: 1,
  },

  /** as theme map variable border-color */
  borderColor: {
    type: 'color',

    rgba: 'rgba(255, 255, 255, 1)',
    hex: '#FFFFFF',
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },

  /** as theme map variable shadow-color */
  shadowColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 0.12)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.12,
  },

  /** as theme map variable box-shadow */
  boxShadow: {
    type: 'box-shadow',

    rgba: 'rgba(0, 0, 0, 0.12)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.12,

    boxShadowParams: ['0px', '4px', '8px'],
  },
};

export const $lightPalette: ColorsMap = {
  /** as theme map variable danger-color */
  dangerColor: {
    type: 'color',

    rgba: 'rgba(255, 64, 88, 1)',
    hex: '#FF4058',
    r: 255,
    g: 64,
    b: 88,
    a: 1,
  },

  /** as theme map variable success-color */
  successColor: {
    type: 'color',

    rgba: 'rgba(16, 164, 38, 1)',
    hex: '#10A426',
    r: 16,
    g: 164,
    b: 38,
    a: 1,
  },

  /** as theme map variable success-light-color */
  successLightColor: {
    type: 'color',

    rgba: 'rgba(15, 172, 39, 1)',
    hex: '#0FAC27',
    r: 15,
    g: 172,
    b: 39,
    a: 1,
  },

  /** as theme map variable success-blue-color */
  successBlueColor: {
    type: 'color',

    rgba: 'rgba(80, 146, 225, 1)',
    hex: '#5092E1',
    r: 80,
    g: 146,
    b: 225,
    a: 1,
  },

  /** as theme map variable warning-color */
  warningColor: {
    type: 'color',

    rgba: 'rgba(255, 176, 57, 1)',
    hex: '#FFB039',
    r: 255,
    g: 176,
    b: 57,
    a: 1,
  },

  /** as theme map variable bg-main-color */
  bgMainColor: {
    type: 'color',

    rgba: 'rgba(255, 255, 255, 1)',
    hex: '#FFFFFF',
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },

  /** as theme map variable bg-additional-color */
  bgAdditionalColor: {
    type: 'color',

    rgba: 'rgba(245, 245, 245, 1)',
    hex: '#F5F5F5',
    r: 245,
    g: 245,
    b: 245,
    a: 1,
  },

  /** as theme map variable bg-block-main-color */
  bgBlockMainColor: {
    type: 'color',

    rgba: 'rgba(242, 242, 242, 1)',
    hex: '#F2F2F2',
    r: 242,
    g: 242,
    b: 242,
    a: 1,
  },

  /** as theme map variable bg-extra-color */
  bgExtraColor: {
    type: 'color',

    rgba: 'rgba(243, 243, 243, 1)',
    hex: '#F3F3F3',
    r: 243,
    g: 243,
    b: 243,
    a: 1,
  },

  /** as theme map variable text-color */
  textColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 1)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  },

  /** as theme map variable text-additional-color */
  textAdditionalColor: {
    type: 'color',

    rgba: 'rgba(115, 115, 115, 1)',
    hex: '#737373',
    r: 115,
    g: 115,
    b: 115,
    a: 1,
  },

  /** as theme map variable text-additional-2-color */
  textAdditional2Color: {
    type: 'color',

    rgba: 'rgba(158, 158, 158, 1)',
    hex: '#9E9E9E',
    r: 158,
    g: 158,
    b: 158,
    a: 1,
  },

  /** as theme map variable text-extra-color */
  textExtraColor: {
    type: 'color',

    rgba: 'rgba(175, 175, 175, 1)',
    hex: '#AFAFAF',
    r: 175,
    g: 175,
    b: 175,
    a: 1,
  },

  /** as theme map variable text-inverse-color */
  textInverseColor: {
    type: 'color',

    rgba: 'rgba(175, 175, 175, 1)',
    hex: '#AFAFAF',
    r: 175,
    g: 175,
    b: 175,
    a: 1,
  },

  /** as theme map variable text-static-color */
  textStaticColor: {
    type: 'color',

    rgba: 'rgba(255, 255, 255, 1)',
    hex: '#FFFFFF',
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },

  /** as theme map variable text-link-color */
  textLinkColor: {
    type: 'color',

    rgba: 'rgba(80, 146, 225, 1)',
    hex: '#5092E1',
    r: 80,
    g: 146,
    b: 225,
    a: 1,
  },

  /** as theme map variable border-color */
  borderColor: {
    type: 'color',

    rgba: 'rgba(213, 213, 213, 1)',
    hex: '#D5D5D5',
    r: 213,
    g: 213,
    b: 213,
    a: 1,
  },

  /** as theme map variable shadow-color */
  shadowColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 0.12)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.12,
  },

  /** as theme map variable box-shadow */
  boxShadow: {
    type: 'box-shadow',

    rgba: 'rgba(0, 0, 0, 0.12)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.12,

    boxShadowParams: ['0px', '4px', '8px'],
  },
};

