<div
  class="app-dialog-notification__wrapper"
  appResizeObserver
  *ngLet="settingsService.screenBreakpoint$ | async"
  (appResizeObserver.widthChange)="
    sizeObserver.value = {
      left: +Math.floor(wrapper.clientWidth / 2) - +Math.floor(window.clientWidth / 2),
      top: +Math.floor(wrapper.clientHeight / 2) - +Math.floor(window.clientHeight / 2)
    };
    cd.detectChanges()
  "
  (appResizeObserver.heightChange)="
    sizeObserver.value = {
      left: +Math.floor(wrapper.clientWidth / 2) - +Math.floor(window.clientWidth / 2),
      top: +Math.floor(wrapper.clientHeight / 2) - +Math.floor(window.clientHeight / 2)
    };
    cd.detectChanges()
  "
  #wrapper
>
  <div
    #window
    class="app-dialog-notification__window app-shadow"
    [ngClass]="
      calculatePosition &&
      'mrgn-lft-' + sizeObserver.value?.left + ' mrgn-top-' + sizeObserver.value?.top
    "
  >
    <div
      class="app-dialog-notification__header"
      [ngVar]="{
        left: +Math.floor(wrapper.clientWidth / 2) - +Math.floor(window.clientWidth / 2),
        top: +Math.floor(wrapper.clientHeight / 2) - +Math.floor(window.clientHeight / 2)
      }"
      #sizeObserver="ngVar"
    >
      <ng-content select="app-dialog-notification-title"></ng-content>

      <app-svg
        type="stroke"
        src="/assets/img/close.svg"
        (click)="_onCloseClick()"
        class="app-dialog-notification__close"
      >
      </app-svg>
    </div>

    <ng-content select="app-dialog-notification-description"></ng-content>

    <ng-content select="app-dialog-notification-footer"></ng-content>
  </div>
</div>
